import Vue from 'vue'
import VueRouter from 'vue-router'
import Views from '../views'
import store from "@/store";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'views',
        component: Views,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('../views/homepage')
            },
            {
                path: '/product',
                name: 'product',
                component: () => import('../views/product'),
                redirect: '/product/productList',
                children:[
                    {
                        path: '/product/productList',
                        name: 'productList',
                        component: () => import('../views/product/productList/index.vue')
                    },
                    {
                        path: '/product/transactionFlow',
                        name: 'transactionFlow',
                        component: () => import('../views/product/transactionFlow/index.vue')
                    },
                    {
                        path: '/product/closingLog',
                        name: 'closingLog',
                        component: () => import('../views/product/closingLog/index.vue')
                    }
                    ,
                    {
                        path: '/product/riskControlManagement',
                        name: 'riskControlManagement',
                        component: () => import('../views/product/riskControlManagement/index.vue')
                    }
                    ,
                    {
                        path: '/product/productList/productListAdd',
                        name: 'productListAdd',
                        component: () => import('../views/product/productList/productListAdd/index.vue')
                    }
                    ,
                    {
                        path: '/product/productList/productListedit',
                        name: 'productListedit',
                        component: () => import('../views/product/productList/productListedit/index.vue')
                    }
                    ]
            },

            {
                path: '/user',
                name: 'user',
                component: () => import('../views/user'),
                redirect: '/user/listOfMembers',
                children: [
                    {
                        path: '/user/listOfMembers',
                        name: 'listOfMembers',
                        component: () => import('../views/user/listOfMembers')
                    },

                    {
                        path: '/user/levelSetting',
                        name: 'levelSetting',
                        component: () => import('../views/user/levelSetting/index.vue')
                    },
                    {
                        path: '/user/membershipNetwork',
                        name: 'membershipNetwork',
                        component: () => import('../views/user/membershipNetwork/index.vue')
                    },
                    {
                        path: '/user/bankCardList',
                        name: 'bankCardList',
                        component: () => import('../views/user/bankCardList/index.vue')
                    },
                    {
                        path: '/user/waterRecord',
                        name: 'waterRecord',
                        component: () => import('../views/user/waterRecord/index.vue')
                    },
                    {
                        path: '/user/topUpRecord',
                        name: 'topUpRecord',
                        component: () => import('../views/user/topUpRecord/index.vue')
                    },
                    {
                        path: '/user/withdrawalRecord',
                        name: 'withdrawalRecord',
                        component: () => import('../views/user/withdrawalRecord/index.vue')
                    },
                    {
                        path: '/user/yuebaoFinancialManagement',
                        name: 'yuebaoFinancialManagement',
                        component: () => import('../views/user/yuebaoFinancialManagement/index.vue')
                    },
                    {
                        path: '/user/yuebaoWithdrawal',
                        name: 'yuebaoWithdrawal',
                        component: () => import('../views/user/yuebaoWithdrawal/index.vue')
                    },
                    ]},
            {
                path: '/info',
                name: 'info',
                component: () => import('../views/info'),
                redirect: '/info/articleList',
                children: [
                    {
                        path: '/info/articleList',
                        name: 'articleList',
                        component: () => import('../views/info/articleList/index.vue')
                    },
                    {
                        path: '/info/articleClassification',
                        name: 'articleClassification',
                        component: () => import('../views/info/articleClassification/index.vue')
                    },
                    {
                        path: '/info/siteLetterList',
                        name: 'siteLetterList',
                        component: () => import('../views/info/siteLetterList/index.vue')
                    }
                    ,
                    {
                        path: '/info/articleList/AddArticleClassification',
                        name: 'AddArticleClassification',
                        component: () => import('../views/info/articleList/AddArticleClassification.vue')
                    }
                    ,
                    {
                        path: '/info/siteLetterList/AddsiteLetter',
                        name: 'AddArticleClassification',
                        component: () => import('../views/info/siteLetterList/AddsiteLetter.vue')
                    }
                ]
            },
            {
                path: '/channel',
                name: 'channel',
                component: () => import('../views/channel'),
                redirect: '/channel/accessPermissionsManagement',
                children: [
                    {
                        path: '/channel/accessPermissionsManagement',
                        name: 'accessPermissionsManagement',
                        component: () => import('../views/channel/accessPermissionsManagement/index.vue')
                    },
                    {
                        path: '/channel/modeOfPayment',
                        name: 'modeOfPayment',
                        component: () => import('../views/channel/modeOfPayment/index.vue')
                    }, {
                        path: '/channel/programParameterConfiguration',
                        name: 'programParameterConfiguration',
                        component: () => import('../views/channel/programParameterConfiguration/index.vue')
                    },
                    {
                        path: '/channel/paymentSettin',
                        name: 'paymentSettin',
                        component: () => import('../views/channel/paymentSettin/index.vue')
                    },


                    {
                        path: '/channel/rewardSettings',
                        name: 'rewardSettings',
                        component: () => import('../views/channel/rewardSettings/index.vue')
                    },{
                        path: '/channel/rotationChartSetting',
                        name: 'rotationChartSetting',
                        component: () => import('../views/channel/rotationChartSetting/index.vue')
                    },{
                        path: '/channel/systemLogManagement',
                        name: 'systemLogManagement',
                        component: () => import('../views/channel/systemLogManagement/index.vue')
                    },{
                        path: '/channel/systemMenuManagement',
                        name: 'systemMenuManagement',
                        component: () => import('../views/channel/systemMenuManagement/index.vue')
                    },{
                        path: '/channel/systemPictureSetting',
                        name: 'systemPictureSetting',
                        component: () => import('../views/channel/systemPictureSetting/index.vue')
                    },{
                        path: '/channel/systemUserManagement',
                        name: 'systemUserManagement',
                        component: () => import('../views/channel/systemUserManagement/index.vue')
                    },{
                        path: '/channel/websiteInformation',
                        name: 'websiteInformation',
                        component: () => import('../views/channel/websiteInformation/index.vue')
                    }]
            },
            {
                path: '/tou2',
                name: 'tou2',
                component: () => import('../components/tou2.vue')
            }
            ,
            {
                path: '/error505',
                name: 'error505',
                component: () => import('../components/error505.vue')
            }
            ,
            {
                path: '/login',
                name: 'login',
                component: () => import('../components/login.vue')
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 全局前置守卫
// router.beforeEach((to, from, next) => {
//     // 检查用户是否已登录
//     if (store.state.islogin) {
//         // 用户已登录，继续导航
//         next();
//     } else {
//         // 用户未登录，重定向到登录页面
//         next({
//             name: 'login',// 或者使用 'path: '/login''，取决于你的路由配置
//             query: { redirect: to.fullPath } // 保存当前路由，以便登录后重定向
//         });
//     }
// });

router.beforeEach((to, from, next) => {
    // 检查用户是否已登录
    const isLoggedIn = store.state.islogin; // 确保变量名与状态中的一致

    // 如果用户已登录或者访问的是登录页面，则直接导航
    if (isLoggedIn || to.name === 'login') {
        next();
    } else {
        // 用户未登录，并且不是访问登录页面，重定向到登录页面
        const redirect = to.fullPath; // 保存当前路由，以便登录后重定向
        next({
            name: 'login', // 使用路由的name属性进行导航，如果配置了的话
            // 如果路由没有name属性，可以使用path属性
            // path: '/login',
            query: { redirect } // 将重定向地址作为查询参数传递
        });
    }
});

export default router

// 解决重复点击报错bug
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err);
}
